
<template>
  <section
    class="pb-7 pt-10 py-xl-9 bg-gradient-primary position-relative overflow-hidden"
  >
    <div class="container">
      <h2 class="display-4 fw-bold text-center text-uppercase mt-7">
        Need Proof?
      </h2>
      <p class="text-center" style="font-size: 20px;">We only take on clients who we believe we can create new leads for.</p>
      <div class="row justify-content-center gy-4">
        <div class="col-auto" v-for="work in featuredWorks" :key="work.url">
          <a :href="work.url" target="_blank" :alt="work.alt">
            <span
              class="d-inline-block rounded hover-shadow-lg bg-cover"
              :style="{ backgroundColor: '#12143d', width: '200px', height: '200px', backgroundImage: 'url(' + work.imageUrl + ')' }"
            ></span>
          </a>
        </div>
      </div>
    </div>
    <div
      class="col-2 bg-black position-absolute top-0 right-0 skew-x-45 mr-n7"
      style="height: 100px"
    ></div>
  </section>
</template>

<script>
export default {
name: 'FeaturedWork',
data() {
  return {
    featuredWorks: [
      {
        url: 'https://slo-pitch.com/',
        imageUrl: 'https://cdn.blacktiecollab.com/admin.blacktietoronto.ca/spn.png',
        alt: 'Slo-Pitch National'
      },
      {
        url: 'https://elevatetheglobe.com/',
        imageUrl: '/media/client-5.png',
        alt: 'Elevate The Globe'
      },
      {
        url: 'https://teamgear.ca/',
        imageUrl: '/media/client-6.png',
        alt: 'TeamGear.ca - Custom Baseball Jerseys, Custom Hockey Jerseys and Accessories'
      },
      {
        url: 'https://showzone.io/',
        imageUrl: 'https://cdn.blacktiecollab.com/showzone.png',
        alt: 'Showzone - MLB The Show Database and Tools'
      },
      {
        url: 'https://intunekundalini.com/',
        imageUrl: 'https://cdn.blacktiecollab.com/admin.blacktietoronto.ca/intune.png',
        alt: 'Intune Kundalini'
      },
      {
        url: 'https://henningpark.com/',
        imageUrl: 'https://cdn.blacktiecollab.com/admin.blacktietoronto.ca/henning2.png',
        alt: 'Henning Park'
      },
      {
        url: 'https://playslopitch.com',
        imageUrl: 'https://cdn.blacktiecollab.com/admin.blacktietoronto.ca/playslopitch.png',
        alt: 'PlaySloPitch.com'
      },
      {
        url: 'https://drtonyortega.com',
        imageUrl: 'https://cdn.blacktiecollab.com/admin.blacktietoronto.ca/drtonyortega.png',
        alt: 'Dr. Tony Ortega'
      },
      {
        url: 'https://lindseylegrande.com',
        imageUrl: 'https://cdn.blacktiecollab.com/admin.blacktietoronto.ca/lindsey.png',
        alt: 'Lindsey Legrande'
      },
      {
        url: 'https://cspl.ca',
        imageUrl: 'https://cdn.blacktiecollab.com/admin.blacktietoronto.ca/cspl.png',
        alt: 'CSPL'
      },
      {
        url: 'https://wcplumbingservices.ca',
        imageUrl: 'https://cdn.blacktiecollab.com/wcplumbingservices/wcplumbingservice.png',
        alt: 'WC Plumbing Services'
      },
      {
        url: 'https://handcraftedhome.ca',
        imageUrl: 'https://cdn.blacktiecollab.com/admin.blacktietoronto.ca/handcraftedhome.png',
        alt: 'Handcrafted Home'
      },
      {
        url: 'https://sportcalcs.com',
        imageUrl: 'https://cdn.blacktiecollab.com/sportscalcs.com/sportscalcs2.png',
        alt: 'SportCalcs'
      }, 
      {
        url: 'https://collegepigskin.gg',
        imageUrl: 'https://cdn.blacktiecollab.com/blacktiecollab.com/collegepigskin.png',
        alt: 'College Pigskin'
      },
      {
        url: 'https://videogamenumbers.gg',
        imageUrl: 'https://cdn.blacktiecollab.com/blacktiecollab.com/vgnbg.png',
        alt: 'Video Game Numbers'
      },
      {
        url: 'https://slopitchcentral.com',
        imageUrl: 'https://cdn.blacktiecollab.com/slopitchcentral/spicsquare.png',
        alt: 'Slo-Pitch Central'
      },
      {
        url: 'https://hamiltonvendingservices.ca',
        imageUrl: 'https://cdn.blacktiecollab.com/blacktiecollab.com/hamiltonvendingsquare.png',
        alt: 'Hamilton Vending Services'
      },
      {
        url: 'https://theriverlinenyc.com',
        imageUrl: 'https://cdn.blacktiecollab.com/blacktiecollab.com/riverline.png',
        alt: 'Riverline Priscilla'
      },
      {
        url: 'https://hamiltonchristmaslightinstall.ca/',
        imageUrl: null,
        alt: 'Hamilton Christmas Light Install'
      },
      {
        url: 'https://einwechterelectric.com/',
        imageUrl: 'https://cdn.blacktiecollab.com/einwechterelectric/einwechterlogo.png',
        alt: 'Einwechter Electric'
      },
      {
        url: 'https://braces.ca/',
        imageUrl: 'https://cdn.blacktiecollab.com/blacktiecollab.com/smilesinmotion.png',
        alt: 'Smiles in Motion - Hamilton & Brantford Orthodontics'
      }
    ]
  };
}
};
</script>