<template>
    <div>
        <section
            class="py-7 py-xl-9 bg-cover"
            style="background-image: url('https://cdn.blacktiecollab.com/blacktiecollab.com/discord.png');"
            >
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <p class="h2 text-gray-300 text-uppercase mb-0" data-aos="fade-up">
                            <!-- We build highly technical softwares built to make you grow -->
                            Tools that make your community better.
                        </p>
                        <h1
                        class="display-3 text-gradient-primary text-uppercase fw-bold mb-6"
                        data-aos="fade-up"
                        data-aos-delay="50"
                        >
                            {{ pageTitle }}
                        </h1>
                    </div>
                </div>
            </div>
        </section>


        <section class="py-7 py-xl-9 position-relative">
            <div
                class="d-none d-xl-block pe-none position-absolute top-0 right-0 bottom-0 left-0 bg-100"
                style="background-image: url('/media/bg-2.png'); opacity: 0.2; background-position: center bottom 150px;"
            ></div>
            <div class="container">
                <h2 class="display-4 fw-bold text-center text-uppercase mb-7 mb-xl-9">
                    Why Choose BlacktieCollab?
                </h2>
                <div class="row justify-content-center gy-7">
                    <div class="col-12 col-md-12 col-xl-12 col-xxl-12" style="font-size: 18px;">
                        <p>Welcome to Blacktie Collaborative, where we specialize in Discord Bot Development, crafting customized solutions that enhance user interaction and streamline community management on Discord. Our expert developers create bots that are not only functional but also integral to fostering vibrant and engaging online communities.</p>

                        <p>At Blacktie Collaborative, we understand that each Discord server has unique needs and objectives. That's why we offer bespoke bot development services tailored to your specific requirements. Whether you need a bot for moderating content, managing tasks, or enhancing user engagement through interactive games and polls, our team has the expertise to deliver high-quality solutions.</p>

                        <p>Our development process involves a thorough understanding of your community's dynamics and goals, ensuring that every bot we create adds real value. From automating routine tasks to providing fun and interactive experiences for members, our bots are designed to make your Discord server more enjoyable and easier to manage.</p>

                        <p>Serving clients in <span v-if="city">{{ cityDisplayName }}</span><span v-else>Hamilton</span> and beyond, Blacktie Collaborative is committed to providing top-tier Discord Bot Development services. Our team employs the latest programming standards and best practices to ensure that your bot is reliable, secure, and scalable to meet the growing demands of your community.</p>

                        <p>Recognizing the importance of user engagement in maintaining an active and growing Discord community, our bots are equipped with features that not only entertain but also encourage participation and interaction among users.</p>

                        <p>If you are looking to elevate your Discord server with custom bot features that can automate processes, enhance engagement, and manage your community more effectively, Blacktie Collaborative is here to help. Regardless of your location, from <span v-if="city">{{ cityDisplayName }}</span> to anywhere else, our developers are ready to assist you in developing the perfect bot for your needs.</p>

                        <p>To explore how our Discord Bot Development services can transform your community experience, click the <strong>Contact Us</strong> button below and start a conversation with our tech experts today.</p>

                    </div>
                </div>
            </div>
        </section>

        
        <WhyWorkWithUs :customWorkWithUsData="workWithUsBoxes"></WhyWorkWithUs>
        <LoveFromOurClients></LoveFromOurClients>
        <ConnectWithUs></ConnectWithUs>
        <OurLocation></OurLocation>
        
    </div>
</template>

<script>
import WhyWorkWithUs from '@/components/WhyWorkWithUs.vue'
import ConnectWithUs from '@/components/ConnectWithUs.vue'
import LoveFromOurClients from '../components/LoveFromOurClients.vue'
import OurLocation from '../components/OurLocation.vue'
import { validCities, getCityDisplayName } from '@/utils/cityData.js'
import { validNiches, getNicheDisplayName } from '@/utils/nicheData.js'

export default {
  name: 'DiscordBotDevelopmentPage',
  components: { WhyWorkWithUs, ConnectWithUs, LoveFromOurClients, OurLocation },
  data() {
    return {
      city: null,
      cityDisplayName: '',
      niche: null,
      nicheDisplayName: '',
    }
  },
  methods: {
    updatePageData(to) {
      const { niche, slug } = to.params;
      
      if (niche && Object.keys(validNiches).includes(niche)) {
        this.niche = niche;
        this.nicheDisplayName = getNicheDisplayName(niche);
      } else {
        this.niche = null;
        this.nicheDisplayName = '';
      }

      if (slug && Object.keys(validCities).includes(slug)) {
        this.city = slug;
        this.cityDisplayName = getCityDisplayName(slug);
      } else {
        this.city = null;
        this.cityDisplayName = '';
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.updatePageData(to));
  },
  async beforeRouteUpdate(to, from, next) {
    this.updatePageData(to);
    await this.$nextTick();
    next();
  },
  mounted() {
    this.updatePageData(this.$route);
  },
  computed: {
    pageTitle() {
      let title = 'Discord Bot Development';
      if (this.nicheDisplayName) {
        title = `${title} for ${this.nicheDisplayName}`;
      }
      if (this.cityDisplayName) {
        title = `${title} in ${this.cityDisplayName}`;
      }
      return title;
    },
    canonicalLink() {
      let link = 'https://blacktiecollab.com/discord-bot-development';
      if (this.niche) {
        link = `${link}/${this.niche}`;
      }
      if (this.city) {
        link = `${link}/${this.city}`;
      }
      return link;
    }
  },
  metaInfo() {
    return {
      title: `${this.pageTitle} - Blacktie Collaborative`,
      meta: [
        {
          name: 'description',
          content: `Blacktie Collaborative offers enterprise-quality ${this.pageTitle.toLowerCase()} solutions, crafting customized bots that enhance user interaction and streamline community management on Discord. Our expert team creates bots tailored to your specific requirements, from moderation to engagement features.`
        },
        {
          property: 'og:title',
          content: `${this.pageTitle} - Blacktie Collaborative`
        },
        {
          property: 'og:description',
          content: `Blacktie Collaborative offers enterprise-quality ${this.pageTitle.toLowerCase()} solutions, crafting customized bots that enhance user interaction and streamline community management on Discord. Our expert team creates bots tailored to your specific requirements, from moderation to engagement features.`
        },
        {
          rel: 'canonical',
          href: this.canonicalLink
        }
      ]
    };
  },
}
</script>