<template>
    <div>
      <section
        class="py-7 py-xl-9 bg-cover"
        style="background-image: url('https://cdn.blacktiecollab.com/admin.blacktietoronto.ca/bg-535.png');"
      >
        <div class="container">
          <div class="row">
            <div class="col-12">
              <p class="h2 text-gray-300 text-uppercase mb-0" data-aos="fade-up">
                Driven by Passion, Crafted for Performance
              </p>
              <h1
                class="display-3 text-gradient-primary text-uppercase fw-bold mb-6"
                data-aos="fade-up"
              >
                Your {{ nicheDisplayName }} Vision Brought to Life
              </h1>
            </div>
          </div>
        </div>
      </section>
  
      <section class="pt-7 pt-xl-9">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 col-lg-10 col-xl-8 text-center">
              <p class="lead text-secondary mb-4">
                At Blacktie Collab, we're not just web developers; we're passionate {{ niche.toLowerCase() }} enthusiasts with a profound expertise in programming. Our love for {{ niche.toLowerCase() }} fuels our drive to create dynamic, engaging websites that bring your vision to life. This unique passion allows us to go beyond the basics, crafting web solutions that are not only functional but truly extraordinary.
              </p>
              <p class="lead text-secondary mb-4">
                We specialize in {{ niche.toLowerCase() }} web development, transforming the unique challenges of {{ niche.toLowerCase() }} programming into our greatest strengths. Whether you need a website for a local {{ niche.toLowerCase() }} team, a dynamic platform for {{ niche.toLowerCase() }} events, or an interactive site for {{ niche.toLowerCase() }} enthusiasts, we have the expertise to deliver.
              </p>
              <p class="lead text-secondary mb-4">
                Our deep understanding of {{ niche.toLowerCase() }} and web development ensures that each project we undertake is a masterpiece, tailored to meet the specific needs of the {{ niche.toLowerCase() }} industry. Partner with us to create a website that stands out and captures the essence of your {{ niche.toLowerCase() }} project, team, or event.
              </p>
            </div>
          </div>
        </div>
      </section>
  
      <OurServices :niche="niche"></OurServices>
      <WhyWorkWithUs></WhyWorkWithUs>
      <LoveFromOurClients></LoveFromOurClients>
      <WhatSetsUsApart></WhatSetsUsApart>
  
      <section class="py-7 py-xl-9 bg-cover">
        <div class="container">
          <div class="row">
            <div class="col-12 text-center">
              <p class="lead text-uppercase mb-0 text-black">SLO-PITCH.Com</p>
              <h2 class="display-4 fw-bold text-gradient-primary text-uppercase mb-4">Case Study: Slo-Pitch National</h2>
              <p class="text-secondary mb-4">Empowering Canada's Slo-Pitch Community with a Unified Digital Experience.</p>
            </div>
          </div>
          <div class="row gy-4 mb-4 align-items-center">
            <div class="col-12 col-lg-6">
              <img src="/media/photo-4.jpg" class="img-fluid" alt="Slo-Pitch National Platform">
            </div>
            <div class="col-12 col-lg-6">
              <div class="text-left">
                <h4 class="fw-bold mb-3">Challenge: Diverse Needs, One Platform</h4>
                <p class="text-secondary mb-4">Slo-Pitch National faced operational inefficiencies due to diverse regional requirements. Their goal: a cohesive system to streamline nationwide operations.</p>
                <h4 class="fw-bold mb-3">Solution: Customized Flexibility</h4>
                <p class="text-secondary mb-4">We developed a multi-regional, multi-language platform, enhancing operations for 300k users. Features like geolocation-based tournament advertising and a comprehensive payment system transformed their digital presence.</p>
                <p class="text-secondary">Our ongoing partnership focuses on expanding connectivity and ease of use, fostering sport growth through innovative digital tools.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
  
      <ConnectWithUs></ConnectWithUs>
      <OurLocation></OurLocation>
    </div>
  </template>
  
  <script>
import ConnectWithUs from "../components/ConnectWithUs.vue";
import LoveFromOurClients from '../components/LoveFromOurClients.vue';
import WhatSetsUsApart from '../components/WhatSetsUsApart.vue';
import OurServices from '../components/OurServices.vue';
import WhyWorkWithUs from '../components/WhyWorkWithUs.vue';
import { getNicheDisplayName } from '../utils/nicheData.js';
import OurLocation from '../components/OurLocation.vue';


export default {
  name: "NichePage",
  components: { ConnectWithUs, LoveFromOurClients, WhatSetsUsApart, WhyWorkWithUs, OurServices, OurLocation },
  computed: {
    niche() {
      return this.$route.params.niche;
    },
    nicheDisplayName() {
      return getNicheDisplayName(this.niche);
    },
    metaDescription() {
      return `Blacktie Collab specializes in ${this.niche.toLowerCase()} web development. We create dynamic, engaging websites tailored to the unique needs of the ${this.niche.toLowerCase()} industry.`;
    },
    products() {
      return [
        { id: 1, name: "League Management Systems", url: `/${this.niche}/league-management` },
        { id: 2, name: "Team Websites", url: `/${this.niche}/team-websites` },
        { id: 3, name: "Tournament Platforms", url: `/${this.niche}/tournament-platforms` },
        { id: 4, name: "Player Statistics Tracking", url: `/${this.niche}/player-statistics` },
        { id: 5, name: "Fan Engagement Portals", url: `/${this.niche}/fan-engagement` },
        { id: 6, name: "E-commerce Solutions", url: `/${this.niche}/ecommerce` },
      ];
    }
  },
  metaInfo() {
    return {
      title: this.nicheDisplayName ? `${this.nicheDisplayName} Web Development | Blacktie Collab` : 'Web Development | Blacktie Collab',
      meta: [
        { name: 'description', content: this.metaDescription }
      ]
    }
  }
};
</script>