<template>
    <div>
        <section
            class="py-7 py-xl-9 bg-cover"
            style="background-image: url('https://cdn.blacktiecollab.com/admin.blacktietoronto.ca/webdesign.png');"
            >
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <p class="h2 text-gray-300 text-uppercase mb-0" data-aos="fade-up">
                        User Experiences that make your customers loyal and Happy
                        </p>
                        <h1
                        class="display-3 text-gradient-primary text-uppercase fw-bold mb-6"
                        data-aos="fade-up"
                        data-aos-delay="50"
                        >
                            {{ pageTitle }}
                        </h1>
                    </div>
                </div>
            </div>
        </section>

        <section class="py-7 py-xl-9 position-relative">
            <div
                class="d-none d-xl-block pe-none position-absolute top-0 right-0 bottom-0 left-0 bg-100"
                style="background-image: url('/media/bg-2.png'); opacity: 0.2; background-position: center bottom 150px;"
            ></div>
            <div class="container">
                <h2 class="display-4 fw-bold text-center text-uppercase mb-7 mb-xl-9">
                    Why Choose BlacktieCollab?
                </h2>
                <div class="row justify-content-center gy-7">
                    <div class="col-12 col-md-12 col-xl-12 col-xxl-12" style="font-size: 18px;">
                        <p>Welcome to the cutting edge of digital innovation at Blacktie Collaborative, where our specialized web design services empower your business with an SEO-optimized, visually appealing, and highly functional website. Our approach goes beyond conventional design; we create customized, mobile-friendly websites that engage visitors and convert them into loyal customers.</p>

                        <p>At Blacktie Collaborative, we reject the cookie-cutter approach. We delve deep into understanding your brand's essence, aligning our strategy with your unique goals, branding, and target audience. This ensures your website isn't just found but also loved and followed.</p>

                        <p>DIY website solutions often fall short of expectations, failing to drive real business results. Our expert team meticulously crafts every aspect of your website from engaging content to swift navigation, optimizing each facet for speed and SEO—from image and title tags to consistent branding and intuitive user experience.</p>

                        <p>Whether you're in <span v-if="city">{{ cityDisplayName }}</span><span v-else>Hamilton</span> or another locality, our services extend to your city. Our dedicated, in-house team ensures that your website makes an unforgettable first impression, telling your story in a way that attracts and retains customers—no outsourcing, just genuine craftsmanship from Blacktie Collaborative.</p>

                        <p>Responsive design is at the core of our web solutions, ensuring that anyone, anywhere, on any device experiences your site at its best. With the majority of internet traffic now mobile, a responsive website isn’t just nice to have, it’s essential for capturing and retaining this growing audience.</p>

                        <p>Looking to elevate your e-commerce? Blacktie Collaborative provides bespoke e-commerce solutions tailored to the unique challenges and goals of your business. Our services are designed to optimize every aspect of your online store, from product display to checkout, ensuring seamless user experiences and maximizing conversion rates.</p>

                        <p>If you're ready to upgrade your online presence, don't hesitate to connect with us. We service all areas, including <span v-if="city">{{ cityDisplayName }}</span>. For a detailed discussion on how we can assist in achieving your digital marketing goals, click the <strong>Contact Us</strong> button below.</p>
                    </div>
                </div>
            </div>
        </section>
        <div class="container  mb-xl-9">
            <h2 class="display-4 fw-bold text-center text-uppercase" style="color: #e0b661">
                Free Site Assessment & Mockup
            </h2>
            <p class="text-center" style="font-size: 18px;">We are so confident in our web design expertise that we offer a FREE site assessment of your current website, as well as a visionary custom mockup of what we want to transform it into. This allows you to see exactly what you will receive before making any commitment.</p>
            <div class="text-center">
            <a href="/free-site-assessment" data-aos="fade-up" data-aos-delay="150" class="btn btn-outline-gradient-primary btn-append aos-init aos-animate"> Claim Your Freebies <span class="btn-append-icon"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-chevron-right fa-w-10"><path fill="currentColor" d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z" class=""></path></svg></span></a>
        </div>
        </div>
        <WhyWorkWithUs :customWorkWithUsData="workWithUsBoxes"></WhyWorkWithUs>
        <LoveFromOurClients></LoveFromOurClients>
        <section class="py-7 py-xl-9 bg-light">
            <div class="container">
                <h2 class="display-4 fw-bold text-center text-uppercase mb-7">
                Case Study: Showzone
                </h2>
                <div class="row align-items-center">
                <div class="col-12 col-lg-6 mb-5 mb-lg-0">
                    <img src="https://cdn.blacktiecollab.com/blacktiecollab.com/brands/showzone/CleanShot 2024-04-17 at 17.14.52.png" alt="Showzone Website" class="img-fluid rounded shadow-lg">
                </div>
                <div class="col-12 col-lg-6">
                    <h3 class="h2 mb-4">Elevating Gaming Experiences</h3>
                    <p class="lead mb-4">We optimized Showzone's web presence, enhancing complex features and user experience. Our work included expanding their technology stack with Events, Matchmaking, and Live Drafts.</p>
                    <ul class="list-unstyled mb-5">
                    <li class="mb-2"><i class="fas fa-check text-primary me-2"></i>Player Database with Complex Sorting</li>
                    <li class="mb-2"><i class="fas fa-check text-primary me-2"></i>Push & Web Notifications</li>
                    <li class="mb-2"><i class="fas fa-check text-primary me-2"></i>Next.js Frontend with React</li>
                    <li class="mb-2"><i class="fas fa-check text-primary me-2"></i>Node.js Backend & Postgres Database</li>
                    </ul>
                    <a href="/brand/showzone" class="btn btn-primary btn-lg">View Full Case Study</a>
                </div>
                </div>
            </div>
        </section>
        <ConnectWithUs></ConnectWithUs>
        <OurLocation></OurLocation>
        
    </div>
</template>

<script>
import WhyWorkWithUs from '@/components/WhyWorkWithUs.vue'
import ConnectWithUs from '@/components/ConnectWithUs.vue'
import LoveFromOurClients from '../components/LoveFromOurClients.vue'
import { validCities, getCityDisplayName } from '@/utils/cityData.js'
import { validNiches, getNicheDisplayName } from '@/utils/nicheData.js'
import OurLocation from '@/components/OurLocation.vue'

export default {
  name: 'WebDesignPage',
  components: { WhyWorkWithUs, ConnectWithUs, LoveFromOurClients, OurLocation },
  data() {
    return {
      city: null,
      cityDisplayName: '',
      niche: null,
      nicheDisplayName: '',
    }
  },
  methods: {
    updatePageData(to) {
      const { niche, slug } = to.params;
      
      if (niche && Object.keys(validNiches).includes(niche)) {
        this.niche = niche;
        this.nicheDisplayName = getNicheDisplayName(niche);
      } else {
        this.niche = null;
        this.nicheDisplayName = '';
      }

      if (slug && Object.keys(validCities).includes(slug)) {
        this.city = slug;
        this.cityDisplayName = getCityDisplayName(slug);
      } else {
        this.city = null;
        this.cityDisplayName = '';
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.updatePageData(to));
  },
  async beforeRouteUpdate(to, from, next) {
    this.updatePageData(to);
    await this.$nextTick();
    next();
  },
  mounted() {
    this.updatePageData(this.$route);
  },
  computed: {
    pageTitle() {
      let title = 'Web Design';
      if (this.nicheDisplayName) {
        title = `${title} for ${this.nicheDisplayName}`;
      }
      if (this.cityDisplayName) {
        title = `${title} in ${this.cityDisplayName}`;
      }
      return title;
    },
    canonicalLink() {
      let link = 'https://blacktiecollab.com/web-design';
      if (this.niche) {
        link = `${link}/${this.niche}`;
      }
      if (this.city) {
        link = `${link}/${this.city}`;
      }
      return link;
    }
  },
  metaInfo() {
    return {
      title: `${this.pageTitle} - Blacktie Collaborative`,
      meta: [
        {
          name: 'description',
          content: `Blacktie Collaborative specializes in custom ${this.pageTitle.toLowerCase()} services, creating SEO-optimized, visually appealing, and highly functional websites. Our expert team crafts mobile-friendly, responsive designs that engage visitors and convert them into loyal customers.`
        },
        {
          property: 'og:title',
          content: `${this.pageTitle} - Blacktie Collaborative`
        },
        {
          property: 'og:description',
          content: `Blacktie Collaborative specializes in custom ${this.pageTitle.toLowerCase()} services, creating SEO-optimized, visually appealing, and highly functional websites. Our expert team crafts mobile-friendly, responsive designs that engage visitors and convert them into loyal customers.`
        },
        {
          rel: 'canonical',
          href: this.canonicalLink
        }
      ]
    };
  },
}
</script>