<template>
    <span>
      <section
        class="py-7 py-xl-9 bg-cover"
        style="background-image: url('https://cdn.blacktiecollab.com/admin.blacktietoronto.ca/bg-535.png');"
      >
        <div class="container">
          <div class="row">
            <div class="col-12">
              <p class="h2 text-gray-300 text-uppercase mb-0" data-aos="fade-up">
                Our Process
              </p>
              <h1
                class="display-3 text-gradient-primary text-uppercase fw-bold mb-6"
                data-aos="fade-up"
              >
                How We Bring Your Dream to Life
              </h1>
            </div>
          </div>
        </div>
        
        <!-- request your site assessment -->
        <!-- review your site assessment, approve, moves to production -->
        <!-- we build your site -->
      </section>
      <!-- <div class="row">
        <div class="col-md-12">
            
        </div>
      </div> -->
      <div class="margin-box">
    <ul class="styled-list">
        <li>
            <span class="number-box">1</span>
            <span class="list-content">
                Click the button below to fill out our quick assessment form. A real human (not some boring AI) will review your site right away.
            </span>
        </li>
    </ul>
</div>
<div class="text-center">
    <a href="/free-site-assessment" class="btn btn-outline-gradient-primary">Get Your Same-day Free Site Assessment</a>
</div>
<div class="margin-box">
    <ul class="styled-list">
        <li>
            <span class="number-box">2</span>
            <span class="list-content">
                Once you get your assessment, if you love what you see, hit the "Let's Get Started" button. We'll start building your digital preview and schedule a quick call to hear your ideas.
            </span>
        </li>
    </ul>
</div>
<div class="margin-box">
    <ul class="styled-list">
        <li>
            <span class="number-box">3</span>
            <span class="list-content">
                We’ll chat on the phone to lock in your goals, and while you wait, we’ll start building your website. Launch day is just 4 weeks away!
            </span>
        </li>
    </ul>
</div>
<div class="margin-box">
    <ul class="styled-list">
        <li>
            <span class="number-box">4</span>
            <span class="list-content">
                At the 3-week mark, we’ll check in to make sure you’re thrilled with the progress, polish up any details, and get everything launch-ready.
            </span>
        </li>
    </ul>
</div>
<div class="margin-box">
    <ul class="styled-list">
        <li>
            <span class="number-box">5</span>
            <span class="list-content">
                <b>Launch time! 99.6% of our websites go live within 4 weeks.</b>
            </span>
        </li>
    </ul>
</div>
          
      <LoveFromOurClients></LoveFromOurClients>  
      <ConnectWithUs></ConnectWithUs>
      <OurLocation></OurLocation>
    </span>
    
</template>

<script>
import LoveFromOurClients from "@/components/LoveFromOurClients.vue";
import ConnectWithUs from "../components/ConnectWithUs.vue";
import OurLocation from "../components/OurLocation.vue";

export default {
  name: 'Navigation',
  components: { LoveFromOurClients, ConnectWithUs, OurLocation },
}
</script>

<style scoped>
.margin-box {
  max-width: 600px;
  margin: 0 auto;
}


.styled-list {
  list-style: none;
  padding: 0;
  max-width: 600px;
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 40px;
}

.styled-list li {
  display: flex;
  align-items: center;
  font-size: 20px;
  margin-bottom: 10px; /* Adjust spacing between items as needed */
}

.number-box {
  width: 70px; /* Adjust size as needed */
  height: 70px; /* Adjust size as needed */
  background-color: #fff;
  border: 5px solid #e7c56d;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 40px;
  color:#e7c56d;
  margin-right: 20px; /* Adjust spacing between number and text as needed */
}

.list-content {
  flex: 1;
}
</style>
