<template>
  <div>
    <section
      class="py-6 py-xl-9 bg-cover"
      style="background-image: url('https://cdn.blacktiecollab.com/admin.blacktietoronto.ca/CleanShot 2024-06-17 at 16.54.56.png');"
    >
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h1 data-aos="fade-up" data-aos-delay="100">
              <span class="text-white text-uppercase fw-bold">
                We help local businesses
              </span>
              <br>
              <span class="display-4 text-gradient-primary text-uppercase fw-bold">
                grow using the internet
              </span>
            </h1>
            <p style="color: #fff; font-size: 22px;" data-aos="fade-up" data-aos-delay="100">We are specialists in helping you find new revenue sources through digital marketing, automating current systems and improving your bottom line.</p>
            <br>
            <router-link
              class="btn btn-outline-gradient-primary btn-append"
              to="/free-site-assessment"
              data-aos="fade-up"
              data-aos-delay="150"
            >
              Find out how
              <span class="btn-append-icon">
                <font-awesome-icon icon="chevron-right" />
              </span>
            </router-link>
          </div>
        </div>
      </div>
    </section>
    <WhyWorkWithUs></WhyWorkWithUs>
    <FeaturedWork></FeaturedWork>
    <LoveFromOurClients></LoveFromOurClients>
    <WhatSetsUsApart></WhatSetsUsApart>
    <!-- <PricingComponent></PricingComponent> -->
    <ConnectWithUs></ConnectWithUs>
    <OurLocation></OurLocation>
  </div>
</template>

<script>
import ConnectWithUs from "../components/ConnectWithUs.vue";
import LoveFromOurClients from '../components/LoveFromOurClients.vue';
import WhyWorkWithUs from '../components/WhyWorkWithUs.vue';
import WhatSetsUsApart from '../components/WhatSetsUsApart.vue';
import FeaturedWork from '../components/FeaturedWork.vue';
// import PricingComponent from '../components/PricingComponent.vue';
import OurLocation from '../components/OurLocation.vue';
export default {
  name: "HomePage",
  metaInfo: {
    title: 'Top Tier Digital Marketing in Hamilton - Blacktie Collaborative',
    meta: [
      {
        name: 'description',
        content: 'We are specialists in helping you find new revenue sources through digital marketing, automating current systems and improving your bottom line.'
      },
      {
        property: 'og:title',
        content: 'Top Tier Digital Marketing in Hamilton - Blacktie Collaborative'
      },
      {
        property: 'og:description',
        content: 'We are specialists in helping you find new revenue sources through digital marketing, automating current systems and improving your bottom line.'
      },
      {
        rel: 'canonical',
        href: 'https://blacktiecollab.com/'
      }
      // Add more meta tags as needed
    ]
  },
  components: { ConnectWithUs, LoveFromOurClients, WhyWorkWithUs, WhatSetsUsApart, FeaturedWork, OurLocation },
};
</script>
