<template>
    <div>
        <section
            class="py-7 py-xl-9 bg-cover"
            style="background-image: url('https://cdn.blacktiecollab.com/blacktiecollab.com/webdesign-1 copy.png');"
            >
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <p class="h2 text-gray-300 text-uppercase mb-0" data-aos="fade-up">
                            Create live, instant interactive experiences
                        </p>
                        <h1
                        class="display-3 text-gradient-primary text-uppercase fw-bold mb-6"
                        data-aos="fade-up"
                        data-aos-delay="50"
                        >
                            Socket Development <span v-if="city">in {{ cityDisplayName }}</span>
                        </h1>
                    </div>
                </div>
            </div>
        </section>

        <section class="py-7 py-xl-9 position-relative">
            <div
                class="d-none d-xl-block pe-none position-absolute top-0 right-0 bottom-0 left-0 bg-100"
                style="background-image: url('/media/bg-2.png'); opacity: 0.2; background-position: center bottom 150px;"
            ></div>
            <div class="container">
                <h2 class="display-4 fw-bold text-center text-uppercase mb-7 mb-xl-9">
                    Socket Development <span v-if="city">in {{ cityDisplayName }}</span>
                </h2>
                <div class="row justify-content-center gy-7">
                    <div class="col-12 col-md-12 col-xl-12 col-xxl-12" style="font-size: 18px;">
                        <p>Welcome to Blacktie Collaborative, where we specialize in advanced Socket Development, crafting real-time communication solutions that power instant data exchange and interaction. Our expert team develops socket-based applications that are essential for dynamic environments where real-time data flow is crucial, such as financial trading platforms, gaming, and live communication tools.</p>

                        <p>At Blacktie Collaborative, we understand the critical role that sockets play in maintaining continuous and seamless data streams between clients and servers. Our development process begins with a thorough analysis of your specific needs, ensuring that the solutions we create offer high performance, low latency, and real-time responsiveness.</p>

                        <p>We employ advanced programming techniques and the latest technologies to build robust socket applications that can handle multiple connections simultaneously without compromising the speed or integrity of data. Our solutions are designed to be scalable, secure, and efficient, enabling your business to thrive in a connected world.</p>

                        <p>Serving clients from <span v-if="city">{{ cityDisplayName }}</span><span v-else>Hamilton</span> and around the globe, Blacktie Collaborative is committed to delivering top-tier Socket Development services. Whether you need WebSocket implementation for a web application or TCP/UDP sockets for a networked software system, we have the expertise to meet your requirements.</p>

                        <p>Recognizing the increasing demand for interactive and real-time applications, our socket solutions are tailored to enhance user engagement and operational efficiency. We ensure that your applications remain competitive by enabling faster decision-making, instant user feedback, and uninterrupted service availability.</p>

                        <p>If you are looking to implement real-time features into your software or need to improve the efficiency of your existing systems, Blacktie Collaborative is here to assist. No matter where you are, from <span v-if="city">{{ cityDisplayName }}</span> to any other region, our developers are ready to help you harness the power of real-time data.</p>

                        <p>To find out more about our Socket Development services and how they can transform your business operations, click the <strong>Contact Us</strong> button below and start a conversation with our development team today.</p>

                    </div>
                </div>
            </div>
        </section>
        
        <WhyWorkWithUs :customWorkWithUsData="workWithUsBoxes"></WhyWorkWithUs>
        <ConnectWithUs></ConnectWithUs>
        <LoveFromOurClients></LoveFromOurClients>
        <OurLocation></OurLocation>
    </div>
</template>

<script>
import WhyWorkWithUs from '@/components/WhyWorkWithUs.vue'
import ConnectWithUs from '@/components/ConnectWithUs.vue'
import LoveFromOurClients from '../components/LoveFromOurClients.vue'
import OurLocation from '../components/OurLocation.vue'


const validCities = {
            'toronto': 'Toronto',
            'hamilton': 'Hamilton',
            'burlington': 'Burlington',
            'brantford': 'Brantford',
            'smithville': 'Smithville',
            'dunnville': 'Dunnville',
            'ancaster': 'Ancaster',
            'oakville': 'Oakville',
            'guelph': 'Guelph',
            'cambridge': 'Cambridge',
            'kitchener': 'Kitchener',
            'milton': 'Milton',
            'puslinch': 'Puslinch',
            'hagersville': 'Hagersville',
            'caledonia': 'Caledonia',
            'haldimand county': 'Haldimand County',
            'cayuga': 'Cayuga',
            'port-dover': 'Port Dover',
            'woodstock': 'Woodstock',
            'jarvis': 'Jarvis',
            'st-catherines': 'St. Catharines', // Note the correction here
            'lincoln': 'Lincoln',
            'niagara-falls': 'Niagara Falls',
            'welland': 'Welland',
            // 'dunnville' is duplicated in your list, so ensure to include it only once or handle it appropriately
            'orillia': 'Orillia',
            'barrie': 'Barrie',
            'newmarket': 'Newmarket',
            'simcoe': 'Simcoe',
            // 'woodstock' is duplicated in your list
            'tilsonburg': 'Tilsonburg',
            // 'cambridge' is duplicated in your list
            'georgina': 'Georgina',
            'warminster': 'Warminster',
            'brechin': 'Brechin',
            'niagara': 'Niagara',
            'washago': 'Washago',
            'oro-station': 'Oro Station',
            'uxbridge': 'Uxbridge',
            'elmvale': 'Elmvale',
            'new-tecumseth': 'New Tecumseth',
            'mansfield': 'Mansfield',
            'mono': 'Mono',
            'dundas': 'Dundas',
            'flamborough': 'Flamborough',
            // Continue with the rest of the cities as needed
    }

export default {
    name: 'WebDesignPage',
    components: { WhyWorkWithUs, ConnectWithUs, LoveFromOurClients, OurLocation },
    data() {
        return {
            city: null,
            cityDisplayName: '', // To store the properly written city name
        }
    },
    beforeRouteEnter(to, from, next) {
        const citySlug = to.params.slug;
        if (citySlug && Object.keys(validCities).includes(citySlug)) {
        next(vm => {
            vm.city = citySlug;
            vm.cityDisplayName = validCities[citySlug]; // Set the properly written city name
        });
        } else {
        next(); // Proceed without setting city if slug does not exist
        }
    },
    async beforeRouteUpdate(to, from, next) {
        const citySlug = to.params.slug;
        if (citySlug && Object.keys(validCities).includes(citySlug)) {
        this.city = citySlug; // Update city data on route update
        this.cityDisplayName = validCities[citySlug]; // Update the properly written city name
        await this.$nextTick();
        }
        next();
    },
    mounted() {
        const citySlug = this.$route.params.slug;
        if (citySlug && Object.keys(validCities).includes(citySlug)) {
        this.city = citySlug;
        this.cityDisplayName = validCities[citySlug]; // Initialize the properly written city name when component is mounted
        }
    },
    computed: {
        // Computed property to get the city display name based on the route
        routeCityDisplayName() {
            const citySlug = this.$route.params.slug;
            return citySlug && Object.keys(validCities).includes(citySlug) ? validCities[citySlug] : null;
        },
    },
    metaInfo() {
        // Use the computed property for dynamic meta title
        const title = this.routeCityDisplayName
            ? `Socket Development - ${this.routeCityDisplayName} - Blacktie Collaborative`
            : 'Socket Development - Websites Without Limitation - Blacktie Collaborative';

        // Generate the dynamic canonical link
        const canonicalLink = this.routeCityDisplayName
            ? `https://blacktiecollab.com/${this.city}-socket-development/`
            : 'https://blacktiecollab.com/socket-development';

        return {
            title: title,
            meta: [
                {
                    name: 'description',
                    content: 'Blacktie Collaborative specializes in advanced Socket Development, crafting real-time communication solutions for dynamic environments. Our expert team develops high-performance, low-latency socket applications for instant data exchange, powering interactive experiences in financial trading, gaming, and live communication tools.'
                },
                {
                    property: 'og:title',
                    content: title
                },
                {
                    property: 'og:description',
                    content: 'Blacktie Collaborative specializes in advanced Socket Development, crafting real-time communication solutions for dynamic environments. Our expert team develops high-performance, low-latency socket applications for instant data exchange, powering interactive experiences in financial trading, gaming, and live communication tools.'
                },
                {
                    rel: 'canonical',
                    href: canonicalLink
                }
                // Add more meta tags as needed
            ]
        };
    },
}
</script>


<style scoped>

</style>