<template>
    <div>
        <section
            class="py-7 py-xl-9 bg-cover"
            style="background-image: url('https://cdn.blacktiecollab.com/blacktiecollab.com/webdesign-1 copy.png');"
            >
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <p class="h2 text-gray-300 text-uppercase mb-0" data-aos="fade-up">
                            We build highly technical softwares built to make you grow
                        </p>
                        <h1
                        class="display-3 text-gradient-primary text-uppercase fw-bold mb-6"
                        data-aos="fade-up"
                        data-aos-delay="50"
                        >
                            {{ pageTitle }}
                        </h1>
                    </div>
                </div>
            </div>
        </section>

        <section class="py-7 py-xl-9 position-relative">
            <div
                class="d-none d-xl-block pe-none position-absolute top-0 right-0 bottom-0 left-0 bg-100"
                style="background-image: url('/media/bg-2.png'); opacity: 0.2; background-position: center bottom 150px;"
            ></div>
            <div class="container">
                <h2 class="display-4 fw-bold text-center text-uppercase mb-7 mb-xl-9">
                    {{ cityDisplayName }} Web Development
                </h2>
                <div class="row justify-content-center gy-7">
                    <div class="col-12 col-md-12 col-xl-12 col-xxl-12" style="font-size: 18px;">
                        <p>Welcome to Blacktie Collaborative, where our specialized web development services transform your digital ideas into reality. Our approach leverages cutting-edge technologies to build SEO-optimized, technically sophisticated websites that not only look stunning but function flawlessly.</p>

                        <p>We at Blacktie Collaborative stand apart by rejecting the standard cookie-cutter solutions. Our process begins with a deep dive into your business requirements, aligning our development strategy with your specific objectives, brand identity, and target market. This results in a bespoke website that excels in performance and user engagement.</p>

                        <p>Standard DIY website builders often miss crucial technical nuances, leading to subpar business outcomes. Our team of expert developers focuses on critical aspects such as database integration, advanced user interactions, scalable architecture, and comprehensive security measures. We ensure every line of code contributes to an intuitive, fast, and secure online experience.</p>

                        <p>Our services are available to clients in <span v-if="city">{{ cityDisplayName }}</span><span v-else>Hamilton</span> and beyond, adapting our expertise to meet your regional needs. Our in-house team of developers ensures your website reflects the highest standards of your industry, with no outsourcing involved, maintaining our commitment to quality and precision in every project we undertake at Blacktie Collaborative.</p>

                        <p>Understanding the importance of responsiveness in today’s mobile-first world, we ensure your website is fully responsive. This means it will perform beautifully on any device, adapting seamlessly to different screen sizes, enhancing user experience, and improving SEO rankings.</p>

                        <p>For those looking to establish or enhance their online store, Blacktie Collaborative offers custom e-commerce development. We tailor every aspect of your e-commerce site to streamline the user journey from landing page to checkout, maximizing conversions and enhancing the shopping experience.</p>

                        <p>Ready to take your digital presence to the next level? Reach out to us for top-tier web development services. Whether you're in <span v-if="city">{{ cityDisplayName }}</span> or any other location, we’re here to help. To start a conversation about your web development needs, click the <strong>Contact Us</strong> button below.</p>

                    </div>
                </div>
            </div>
        </section>
        <WhyWorkWithUs :customWorkWithUsData="workWithUsBoxes"></WhyWorkWithUs>
        <LoveFromOurClients></LoveFromOurClients>
        
        <section class="py-7 py-xl-9 bg-light">
            <div class="container">
                <h2 class="display-4 fw-bold text-center text-uppercase mb-7">
                Case Study: Slo-Pitch National
                </h2>
                <div class="row align-items-center">
                <div class="col-12 col-lg-6 mb-5 mb-lg-0">
                    <img src="https://cdn.blacktiecollab.com/blacktiecollab.com/brands/slo-pitch-national/CleanShot%202024-04-17%20at%2017.01.41.png" alt="Slo-Pitch National Website" class="img-fluid rounded shadow-lg">
                </div>
                <div class="col-12 col-lg-6">
                    <h3 class="h2 mb-4">Revolutionizing Sports Management</h3>
                    <p class="lead mb-4">We developed a comprehensive web solution for Slo-Pitch National, transforming their online presence and streamlining their operations. Our work included creating a robust membership management system and enhancing user engagement.</p>
                    <ul class="list-unstyled mb-5">
                    <li class="mb-2"><i class="fas fa-check text-primary me-2"></i>Custom Membership Management System</li>
                    <li class="mb-2"><i class="fas fa-check text-primary me-2"></i>Tournament Registration and Scheduling</li>
                    <li class="mb-2"><i class="fas fa-check text-primary me-2"></i>Real-time Score Tracking and Statistics</li>
                    <li class="mb-2"><i class="fas fa-check text-primary me-2"></i>Responsive Design for Mobile Access</li>
                    </ul>
                    <a href="/brand/slo-pitch-national" class="btn btn-primary btn-lg">View Full Case Study</a>
                </div>
                </div>
            </div>
        </section>

        <ConnectWithUs></ConnectWithUs>
        <OurLocation></OurLocation>
        
    </div>
</template>

<script>
import WhyWorkWithUs from '@/components/WhyWorkWithUs.vue'
import ConnectWithUs from '@/components/ConnectWithUs.vue'
import LoveFromOurClients from '../components/LoveFromOurClients.vue'
import { validCities, getCityDisplayName } from '@/utils/cityData.js'
import { validNiches, getNicheDisplayName } from '@/utils/nicheData.js'
import OurLocation from '@/components/OurLocation.vue'

export default {
  name: 'WebDesignPage',
  components: { WhyWorkWithUs, ConnectWithUs, LoveFromOurClients, OurLocation },
  data() {
    return {
      city: null,
      cityDisplayName: '',
      niche: null,
      nicheDisplayName: '',
    }
  },
  methods: {
    updatePageData(to) {
      const { niche, slug } = to.params;
      
      if (niche && Object.keys(validNiches).includes(niche)) {
        this.niche = niche;
        this.nicheDisplayName = getNicheDisplayName(niche);
      } else {
        this.niche = null;
        this.nicheDisplayName = '';
      }

      if (slug && Object.keys(validCities).includes(slug)) {
        this.city = slug;
        this.cityDisplayName = getCityDisplayName(slug);
      } else {
        this.city = null;
        this.cityDisplayName = '';
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.updatePageData(to));
  },
  async beforeRouteUpdate(to, from, next) {
    this.updatePageData(to);
    await this.$nextTick();
    next();
  },
  mounted() {
    this.updatePageData(this.$route);
  },
  computed: {
    pageTitle() {
      let title = 'Web Development';
      if (this.nicheDisplayName) {
        title = `${title} for ${this.nicheDisplayName}`;
      }
      if (this.cityDisplayName) {
        title = `${title} in ${this.cityDisplayName}`;
      }
      return title;
    },
    canonicalLink() {
      let link = 'https://blacktiecollab.com/web-development';
      if (this.niche) {
        link = `${link}/${this.niche}`;
      }
      if (this.city) {
        link = `${link}/${this.city}`;
      }
      return link;
    }
  },
  metaInfo() {
    return {
      title: `${this.pageTitle} - Blacktie Collaborative`,
      meta: [
        {
          name: 'description',
          content: `Blacktie Collaborative specializes in custom ${this.pageTitle.toLowerCase()} services, creating SEO-optimized, visually appealing, and highly functional websites. Our expert team crafts mobile-friendly, responsive designs that engage visitors and convert them into loyal customers.`
        },
        {
          property: 'og:title',
          content: `${this.pageTitle} - Blacktie Collaborative`
        },
        {
          property: 'og:description',
          content: `Blacktie Collaborative specializes in custom ${this.pageTitle.toLowerCase()} services, creating SEO-optimized, visually appealing, and highly functional websites. Our expert team crafts mobile-friendly, responsive designs that engage visitors and convert them into loyal customers.`
        },
        {
          rel: 'canonical',
          href: this.canonicalLink
        }
      ]
    };
  },
}
</script>