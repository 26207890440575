<template>
    <div>
        <section
            class="py-7 py-xl-9 bg-cover"
            style="background-image: url('https://cdn.blacktiecollab.com/blacktiecollab.com/growth.png');"
            >
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <p class="h2 text-gray-300 text-uppercase mb-0" data-aos="fade-up">
                            Let's see where you're at and assess where we can bring you
                        </p>
                        <h1
                        class="display-3 text-gradient-primary text-uppercase fw-bold mb-6"
                        data-aos="fade-up"
                        data-aos-delay="50"
                        >
                            {{ pageTitle }}
                        </h1>
                    </div>
                </div>
            </div>
        </section>

        <section class="py-7 py-xl-9 position-relative">
            <div
                class="d-none d-xl-block pe-none position-absolute top-0 right-0 bottom-0 left-0 bg-100"
                style="background-image: url('/media/bg-2.png'); opacity: 0.2; background-position: center bottom 150px;"
            ></div>
            <div class="container">
                <h2 class="display-4 fw-bold text-center text-uppercase mb-7 mb-xl-9">
                    Why Choose BlacktieCollab?
                </h2>
                <div class="row justify-content-center gy-7">
                    <div class="col-12 col-md-12 col-xl-12 col-xxl-12" style="font-size: 18px;">
                        <p>Welcome to Blacktie Collaborative, where our growth strategizing services are tailored to help your business scale and thrive in a competitive market. With a focus on sustainable and strategic growth, we design custom solutions that align with your business goals and drive significant market impact.</p>

                        <p>At Blacktie Collaborative, we believe that growth involves more than just increasing numbers—it requires a deep understanding of market trends, customer behavior, and innovative tactics. Our approach integrates business analysis, market research, and customer insights to develop strategies that are not only effective but also adaptable to changing market conditions.</p>

                        <p>We specialize in creating multifaceted growth strategies that include digital marketing, brand development, and customer engagement techniques. By leveraging state-of-the-art tools and analytics, we enhance your visibility, strengthen your brand presence, and improve engagement across all platforms.</p>

                        <p>Our services extend beyond local boundaries, catering to businesses in <span v-if="city">{{ cityDisplayName }}</span><span v-else>Hamilton</span> and beyond. No matter where you are located, Blacktie Collaborative's team of growth strategists works tirelessly to ensure that your business not only meets its current objectives but also secures a robust pathway for future success.</p>

                        <p>Recognizing the critical role of digital presence in growth, we ensure your strategies are optimized for the digital age, focusing on SEO, social media marketing, and content optimization to keep your business ahead of the curve.</p>

                        <p>Are you ready to elevate your business operations and achieve remarkable growth? Blacktie Collaborative offers comprehensive growth strategizing services designed to turn your business ambitions into reality. No matter your location, from <span v-if="city">{{ cityDisplayName }}</span> to anywhere else, we are here to guide you through every step of your growth journey.</p>

                        <p>To discuss how we can help you strategize for growth, click the <strong>Contact Us</strong> button below and start a conversation with one of our expert strategists today.</p>

                    </div>
                </div>
            </div>
        </section>

        
        <WhyWorkWithUs :customWorkWithUsData="workWithUsBoxes"></WhyWorkWithUs>
        <LoveFromOurClients></LoveFromOurClients>
        <ConnectWithUs></ConnectWithUs>
        <OurLocation></OurLocation>
        
    </div>
</template>

<script>
import WhyWorkWithUs from '@/components/WhyWorkWithUs.vue'
import ConnectWithUs from '@/components/ConnectWithUs.vue'
import LoveFromOurClients from '../components/LoveFromOurClients.vue'
import OurLocation from '../components/OurLocation.vue'
import { validCities, getCityDisplayName } from '@/utils/cityData.js'
import { validNiches, getNicheDisplayName } from '@/utils/nicheData.js'


export default {
  name: 'GrowthStrategizingPage',
  components: { WhyWorkWithUs, ConnectWithUs, LoveFromOurClients, OurLocation },
  data() {
    return {
      city: null,
      cityDisplayName: '',
      niche: null,
      nicheDisplayName: '',
      workWithUsBoxes: [
        // Add your workWithUsBoxes data here
      ]
    }
  },
  methods: {
    updatePageData(to) {
      const { niche, slug } = to.params;
      
      if (niche && Object.keys(validNiches).includes(niche)) {
        this.niche = niche;
        this.nicheDisplayName = getNicheDisplayName(niche);
      } else {
        this.niche = null;
        this.nicheDisplayName = '';
      }

      if (slug && Object.keys(validCities).includes(slug)) {
        this.city = slug;
        this.cityDisplayName = getCityDisplayName(slug);
      } else {
        this.city = null;
        this.cityDisplayName = '';
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => vm.updatePageData(to));
  },
  async beforeRouteUpdate(to, from, next) {
    this.updatePageData(to);
    await this.$nextTick();
    next();
  },
  mounted() {
    this.updatePageData(this.$route);
  },
  computed: {
    pageTitle() {
      let title = 'Growth Strategizing';
      if (this.nicheDisplayName) {
        title = `${title} for ${this.nicheDisplayName}`;
      }
      if (this.cityDisplayName) {
        title = `${title} in ${this.cityDisplayName}`;
      }
      return title;
    },
    canonicalLink() {
      let link = 'https://blacktiecollab.com/growth-strategizing';
      if (this.niche) {
        link = `${link}/${this.niche}`;
      }
      if (this.city) {
        link = `${link}/${this.city}`;
      }
      return link;
    }
  },
  metaInfo() {
    return {
      title: `${this.pageTitle} - Blacktie Collaborative`,
      meta: [
        {
          name: 'description',
          content: `Blacktie Collaborative offers tailored ${this.pageTitle.toLowerCase()} services to help businesses scale and thrive. Our expert team develops custom strategies integrating market analysis, digital marketing, and customer engagement techniques to drive sustainable growth and market impact.`
        },
        {
          property: 'og:title',
          content: `${this.pageTitle} - Blacktie Collaborative`
        },
        {
          property: 'og:description',
          content: `Blacktie Collaborative offers tailored ${this.pageTitle.toLowerCase()} services to help businesses scale and thrive. Our expert team develops custom strategies integrating market analysis, digital marketing, and customer engagement techniques to drive sustainable growth and market impact.`
        },
        {
          rel: 'canonical',
          href: this.canonicalLink
        }
      ]
    };
  },
}
</script>