<template>
  <div>
    <section class="py-7 py-xl-9 position-relative">
      <!-- <div
        class="d-none d-xl-block pe-none position-absolute top-0 right-0 bottom-0 left-0 bg-100"
        style="background-image: url('/media/bg-2.png'); background-position: center bottom 150px;"
      ></div> -->
      <div class="container">
        <h2 class="display-5 fw-bold text-center text-uppercase mb-7 mb-xl-9">
          There are <u>three</u> pillars that are often ignored, limiting success as a local business
        </h2>
        <div class="row justify-content-center gy-7">
          <div class="col-12 col-md-6 col-xl-4 col-xxl-3" v-for="box in workWithUsBoxes" :key="box.id">
            <div class="card h-100 border-bottom border-primary border-bottom-10">
              <div class="card-body text-center py-7">
                <img :src="box.imgSrc" class="of-contain mb-7" width="120" height="120" :alt="box.imgAlt" />
                <h4 class="text-uppercase"><b>{{ box.title }}</b></h4>
                <p class="lead mb-0">{{ box.description }}</p>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </section>
    <section 
      class="py-7 py-xl-9 position-relative overflow-hidden bg-cover"
      style="background-image: url('/media/bg-2.jpg')">
      <h2 class="display-4 fw-bold text-center text-uppercase mb-7 mb-xl-9 text-gradient-primary text">
          Our guarantee to you:
        </h2>
        <h2 class="display-5 text-white fw-bold text-center text-uppercase mb-4 mb-xl-9">
          We will boost your traffic with potential customers in 90 days or we'll issue a full refund
        </h2>
        <p style="font-size: 20px; text-align: center; color: #fff;">You may think that's a pretty serious guarantee but: <u>we only take on clients we're confident we can help.</u></p>
        <div style="text-align: center;">
          <router-link
            class="btn btn-outline-gradient-primary btn-append"
            to="/free-site-assessment"
            style="margin-top: 50px;"
            data-aos="fade-up"
            data-aos-delay="150"
          >
            Let's chat
            <span class="btn-append-icon">
              <font-awesome-icon icon="chevron-right" />
            </span>
          </router-link>
        </div>
    </section>
  </div>


    <!--  -->
</template>

<script>
export default {
  name: 'WhyWorkWithUs',
  props: {
    customWorkWithUsData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
        defaultWorkWithUsBoxes: [
        {
            id: 1,
            imgSrc: 'https://cdn.blacktiecollab.com/blacktiecollab.com/Foundational SEO.png',
            imgAlt: 'Search Engine Optimization',
            title: 'Search Engine Optimization',
            description: "We don't pretend to be SEO-first, we ARE SEO-first. We help you rank higher in search engines, increasing your visibility and driving more traffic to your website. We've helped businesses increase their online sales by 100% or more, growing their revenue and customer base. Some of our best results include 10x growth! Are you next?"
        },
        {
            id: 2,
            imgSrc: 'https://cdn.blacktiecollab.com/blacktiecollab.com/Unmatched Speeds All Over.png',
            imgAlt: 'Automating Repetitive Tasks',
            title: 'Automating Repetitive Tasks',
            description: "We help you automate repetitive tasks, freeing up your time to focus on what you do best. We also ship faster than our competitors, getting your website working for you quicker. Experience unmatched speed—both in website performance and our responsive customer service, ensuring your business never misses a beat."
        },
        {
            id: 3,
            imgSrc: 'https://cdn.blacktiecollab.com/blacktiecollab.com/Responsive Websiite Development.png',
            imgAlt: 'Our Designs and Features are Not Just Market-Leading—They\'re Trendsetting',
            title: 'Google My Business Optimization',
            description: 'Your Google My Business Listing is the most important thing you can do for your local business. Google will serve your website to people searching your services in your area. We help you optimize your listing to get more traffic, customers, and sales.'
        }
      ]
    };
  },
  computed: {
    workWithUsBoxes() {
      // If customWorkWithUsData prop is provided and not empty, use it. Otherwise, use default data.
      return this.customWorkWithUsData.length > 0 ? this.customWorkWithUsData : this.defaultWorkWithUsBoxes;
    }
  }
};
</script>
